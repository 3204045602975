import React, { useRef } from 'react';

import Slider from 'react-slick';
import { useStaticQuery, graphql } from 'gatsby';
import PublicationCard from './PublicationCard';
import PrevArrow from '../common/PrevArrow';
import NextArrow from '../common/NextArrow';
import { viewMoreButton } from '../../translations/buttons';
import { categoriesTranslations as publicationsCategoriesTranslations } from '../../translations/publications';
import { categoriesTranslations as newsCategoriesTranslations } from '../../translations/news';

const PublicationSlider = ({ title, isNew, articles, currentLanguage, basePath }) => {
  const data = useStaticQuery(
    graphql`
      query PublicationSliderQuery {
        site {
          siteMetadata {
            cmsUrl
          }
        }
      }
    `
  );

  const cmsURL = data.site.siteMetadata.cmsUrl
  const categoriesTranslations = isNew ? newsCategoriesTranslations : publicationsCategoriesTranslations
  const categories = categoriesTranslations[currentLanguage]

  const slickRef = useRef();
  const slidesToShow = articles.length === 1 ? 1 : 2;

  const sliderSettings = {
    dots: true,
    arrows: false,
    infinite: true,
    focusOnSelect: true,
    slidesToShow: slidesToShow,
    appendDots: dots => <ul>{dots}</ul>,
    customPaging: i => (
      <button>
        <span style={{ visibility: 'hidden' }}>{i + 1}</span>
      </button>
    ),
    responsive: [
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          initialSlide: 0,
        },
      },
    ],
  };

  return (
    <section className="publications">
      <div className="container">
        <div className="row">
          <div className="col-md-11 mx-auto publications__container">
            <div className="publications__header">
              <h2>
                <strong>{title}</strong>
              </h2>
              {articles.length > 2 && (
                <div className="slider-buttons">
                  <PrevArrow className="btn arrow-btn" onClick={() => slickRef.current.slickPrev()} />
                  <NextArrow className="btn arrow-btn" onClick={() => slickRef.current.slickNext()} />
                </div>
              )}
            </div>

            <div className="publications__slider">
              <Slider ref={slickRef} {...sliderSettings}>
                {articles.map(article => {
                  const titleTranslations = {
                    'en-US': article.titulo_en,
                    'es-ES': article.titulo_es,
                    'fr-FR': article.titulo_fr,
                    'pt-BR': article.titulo_pt,
                  };
                  const messageTranslations = {
                    'en-US': article.mensaje_en,
                    'es-ES': article.mensaje_es,
                    'fr-FR': article.mensaje_fr,
                    'pt-BR': article.mensaje_pt,
                  };

                  return (
                    <PublicationCard
                      key={article.id}
                      title={titleTranslations[currentLanguage] || article.titulo}
                      description={article.summary}
                      mensaje={messageTranslations[currentLanguage] || article.mensaje}
                      date={article.fecha || article.fecha_publicacion}
                      tag={categories[article.categoria]}
                      image={article.imagen && `${cmsURL}/assets/${article.imagen?.id}`}
                      to={`${basePath}/${isNew ? 'news' : 'publications'}/${article.slug}`}
                      buttonText={viewMoreButton[currentLanguage]}
                      currentLanguage={currentLanguage}
                    />
                  );
                })}
              </Slider>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default PublicationSlider;
