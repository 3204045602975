import React from 'react';

import sanitizeHtml from 'sanitize-html';
import { useLocation } from '@reach/router';

import SEO from '../components/seo';
import { graphql, Link } from 'gatsby';
import { Layout } from '../layout/Layout';
import PublicationSlider from '../components/common/PublicationSlider';

import { FiArrowLeft, FiArrowRight } from 'react-icons/fi';
import { HiMail } from 'react-icons/hi';
import { FaFacebookF, FaLinkedinIn, FaTwitter } from 'react-icons/fa';
import { formatDate } from '../utils/helpers';
import { returnButton, viewReportButton } from '../translations/buttons';

export const query = graphql`
  query PublicacionQuery {
    site {
      siteMetadata {
        siteUrl
        cmsUrl
      }
    }
  }
`;

const Publicacion = ({ data, pageContext }) => {
  const relatedArticles = pageContext.relatedPosts;
  const { related_title } = pageContext.translations.filter(
    t => t.languages_code.code === pageContext.languageCode
  )[0];

  // Page URL
  const location = useLocation();
  const pageURL = data.site.siteMetadata.siteUrl + location.pathname;
  const cmsURL = data.site.siteMetadata.cmsUrl;

  // Get translations of every category
  const { categoriesTranslations, translationStrings } = pageContext;
  const categories = {};
  categoriesTranslations.forEach(choice => {
    const choiceTranslationKey = choice.text.split(':')[1];

    categories[choice.value] = translationStrings.filter(
      ({ key }) => key === choiceTranslationKey
    )[0]['translations'];
  });

  return (
    <Layout pageId="publicacion" currentLanguage={pageContext.languageCode}>
      <section className="article">
        <div className="container">
          <div className="row">
            <div className="col-md-11 mx-auto article__header">
              <Link to={`${pageContext.basePath}/publications`} className="btn">
                <span>
                  <FiArrowLeft />
                </span>{' '}
                {returnButton[pageContext.languageCode]}
              </Link>

              <div className="info">
                {pageContext.categoria ? (
                  <span className="tag">
                    {
                      categories[pageContext.categoria][
                      pageContext.languageCode
                      ]
                    }
                  </span>
                ) : null}
                {pageContext.fecha ? (
                  <span className="date">
                    {formatDate(pageContext.fecha, pageContext.languageCode)}
                  </span>
                ) : null}
              </div>
              <h1>{pageContext.titulo}</h1>

              {pageContext.imagen && (
                <div className="article__img">
                  <img src={`${cmsURL}/assets/${pageContext.imagen?.id}`} />
                </div>
              )}
            </div>

            <div className="col-md-10 mx-auto">
              <div className="article__content">
                <div className="share-buttons">
                  <ul>
                    <li>
                      <a
                        target="_blank"
                        rel="noreferrer noopener"
                        href={`http://www.facebook.com/sharer.php?${new URLSearchParams(
                          {
                            u: pageURL,
                          }
                        )}`}
                      >
                        <FaFacebookF />
                      </a>
                    </li>
                    <li>
                      <a
                        target="_blank"
                        rel="noreferrer noopener"
                        href={`https://linkedin.com/sharing/share-offsite/?${new URLSearchParams(
                          {
                            url: pageURL,
                          }
                        )}`}
                      >
                        <FaLinkedinIn />
                      </a>
                    </li>
                    <li>
                      <a
                        target="_blank"
                        rel="noreferrer noopener"
                        href={`https://twitter.com/intent/tweet?${new URLSearchParams(
                          {
                            url: pageURL,
                          }
                        )}`}
                      >
                        <FaTwitter />
                      </a>
                    </li>
                    <li>
                      <a
                        href={`mailto:?${new URLSearchParams({
                          subject: pageContext.titulo,
                          body: pageURL,
                        })}`}
                      >
                        <HiMail />
                      </a>
                    </li>
                  </ul>
                </div>

                <div
                  dangerouslySetInnerHTML={{
                    __html: sanitizeHtml(pageContext.mensaje),
                  }}
                />
              </div>
              {pageContext.reporte ? (
                <a
                  href={`${cmsURL}/assets/${pageContext.reporte.id}/${pageContext.reporte.filename_download}`}
                  className="btn"
                >
                  {viewReportButton[pageContext.languageCode]}
                  <span>
                    <FiArrowRight />
                  </span>
                </a>
              ) : null}
            </div>
          </div>
        </div>
      </section>

      {relatedArticles.length > 1 && (
        <PublicationSlider
          title={related_title}
          isNew={false}
          articles={relatedArticles}
          currentLanguage={pageContext.languageCode}
          basePath={pageContext.basePath}
        />
      )}
    </Layout>
  );
};

export default Publicacion;

export const Head = ({ pageContext }) => <SEO title={pageContext.titulo} />;
