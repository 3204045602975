export const categoriesTranslations = {
  'en-US': {
    Todos: 'All',
    Eventos: 'Events',
    Clientes: 'Clients',
    Novedades: 'What’s New',
    Otros: 'Other',
  },
  'es-ES': {
    Todos: 'Todos',
    Eventos: 'Eventos',
    Clientes: 'Clientes',
    Novedades: 'Novedades',
    Otros: 'Otros',
  },
  'fr-FR': {
    Todos: 'Tous',
    Eventos: 'Événements',
    Clientes: 'Clients',
    Novedades: 'Nouvelles',
    Otros: 'Autre',
  },
  'pt-BR': {
    Todos: 'Todos',
    Eventos: 'Eventos',
    Clientes: 'Clientes',
    Novedades: 'Novidades',
    Otros: 'Outros',
  },
};
